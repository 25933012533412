import { Link } from 'gatsby';
import get from 'lodash/get';
import React from 'react';

import profileImg from '../../images/profile.jpg';

const classes = {
  wrapper: 'block mb-6 md:flex',
  imageWrapper: 'w-full max-w-150',
  image: 'rounded-full transform transition-all duration-150 hover:scale-105',
  contentWrapper: 'flex-none pt-6 md:pt-1 md:flex-1 md:pl-20',
  name: 'text-5xl text-gray-900 font-bold leading-tight hover:text-black',
  description: 'text-gray-600',
  list: 'mt-6 uppercase tracking-wider',
  item: 'inline list-none pr-4',
  ada: 'ada-label',
  link:
    'inline-block py-2 font-semibold text-xs text-gray-600 hover:text-black',
};

const Header = ({ metadata = {}, noBlog = false }) => {
  const twitter = get(metadata, 'author', false);
  const github = get(metadata, 'github', false);
  const linkedin = get(metadata, 'linkedin', false);

  return (
    <div className={classes.wrapper}>
      <div className={classes.imageWrapper}>
        <Link to="/">
          <img className={classes.image} src={profileImg} alt={metadata.name} />
        </Link>
      </div>
      <div className={classes.contentWrapper}>
        <h1 className={classes.name}>
          <Link to="/">
            <svg 
	width="454.9"
	height="37"
	fill="#fff"
	viewBox="0 0 454.9 37"
	>
		<path 
		d="M286,34.8c-3.3-0.2-4.9-2.5-6.2-5.2c-1.5-3-3.6-5.4-6.7-7c-0.4-0.2-0.7-0.4-1-0.7c-1.5-1.3-1.6-3-0.1-4.3
			c1.3-1.1,2.7-2.2,4-3.2c1.6-1.4,2.2-3.1,1.7-5.2c-0.4-1.9-1.8-3-3.6-3.4c-1-0.2-2-0.4-3.1-0.4c-1.6,0-2,0.5-2,2
			c0,8.1,0,16.2,0,24.2c0,2.3-0.1,2.4-2.3,2.4c-1.6,0-3.2,0-4.8,0c-1.5,0-1.8-0.2-1.8-1.8c0-4.3,0-8.6,0-12.8c0-4.9,0-9.8,0.1-14.6
			c0-1.9,0.1-1.9,2-1.9c5.8-0.1,11.7-0.2,17.5-0.2c1.1,0,2.2,0.2,3.3,0.5c2.9,0.7,4.7,2.6,4.9,5.2c0.3,3.1-1.1,5.9-3.8,7
			c-1.7,0.7-3.6,1-5.4,1.4c-1.3,0.3-2.6,0.5-3.9,0.7c-0.4,0.1-0.8,0.4-1.2,0.5c0,0.2,0,0.3,0,0.5c0.6,0.1,1.3,0.3,1.9,0.4
			c2,0.4,4,0.6,5.9,1.2c2.4,0.7,4.2,2.4,5.4,4.7c0.6,1.2,1.2,2.5,1.7,3.8c0.7,1.7,1.4,3.3,2.9,4.4c0.2,0.2,0.3,0.4,0.7,0.9
			c-1.2,0.3-2.1,0.6-3.1,0.8C288.1,34.8,287,34.8,286,34.8z"
		/>
		<path 
		d="M452.1,32.4c-0.3,0.8-0.9,1-1.7,1.1c-1.8,0-3.7,0.1-5.5,0.1c-1.4,0-1.8-0.4-1.8-1.8c0-4.3,0-8.6,0-12.8
			c0-1.5-0.2-1.6-1.6-1.3c-2.5,0.5-4.9,1-7.4,1.6c-2.2,0.6-2.3,1-2.2,3.3c0.1,3.1,0.1,6.2,0.2,9.3c0,1.1-0.4,1.6-1.5,1.6
			c-2,0-3.9,0-5.9,0.1c-1,0-1.4-0.4-1.4-1.4c0-9.3,0-18.6,0-28c0-1.1,0.5-1.6,1.6-1.6c1.8,0,3.7,0,5.5,0c1.4,0,1.7,0.4,1.7,1.9
			c0,3.3-0.1,6.6-0.2,10c-0.1,3.2-0.1,3.2,3,2.8c1.9-0.2,3.8-0.3,5.6-0.6c2.4-0.4,2.7-0.9,2.7-3.3c0-2.9-0.1-5.8-0.1-8.8
			c0-1.7,0.3-2,2.1-2c1.7,0,3.4,0.2,5.1,0.1c1.2,0,1.6,0.5,1.6,1.6c0,3.5,0,7,0,10.4c0,0.5,0.1,0.9,0.1,1.4
			C452.1,21.5,452.1,27,452.1,32.4z"
		/>
		<path 
		d="M352.5,2.2c0.3,0.8,0.7,1.5,0.9,2.4c0.2,1.1,0.3,2.3,0.4,3.5c0.1,1.3-0.1,1.6-1.4,1.6c-3,0.1-6.1,0.1-9.1,0.1
			c-1.3,0-2.8-0.1-3.5,1.3c-0.7,1.4,0.1,2.6,0.9,3.6c1.4,1.8,2.9,3.6,4.5,5.3c1.9,2.1,3.2,4.5,3.8,7.2c0.5,2,0.3,3.8-0.8,5.5
			c-0.3,0.4-0.9,0.9-1.4,0.9c-2.9,0.1-5.8,0.1-8.6,0.1c-0.7,0-1.2-0.1-0.8-1c1.8-4,1.4-8.2,0.9-12.4c-0.2-1.4-0.7-2.8-0.8-4.2
			c-0.1-2,0-4,0-6.3c-2,0.1-4.1,0.2-6.3,0.4c-1.9,0.1-1.9,0.1-2.3-1.7c-0.3-1.2-0.6-2.4-0.8-3.6c-0.3-1.4,0-1.9,1.5-2
			c2.7-0.2,5.4-0.2,8.1-0.3c3.1-0.1,6.2-0.1,9.2-0.2c0.3,0,0.5-0.1,0.8-0.1C349.3,2.2,350.9,2.2,352.5,2.2z"
		/>
		<path 
		d="M310.1,33.6c0.5-1.2,0.8-2.1,1.2-3.1c-0.1-0.1-0.2-0.2-0.3-0.2c-0.4,0.2-0.9,0.3-1.3,0.5c-1.9,0.8-3.8,1.8-5.8,2.4
			c-1.2,0.4-2.7,0.5-4,0.3c-3.1-0.5-4.4-2.2-5.1-5.8c-0.5-2.9-0.1-5.7,0.6-8.4c1-3.9,2-7.8,1.9-11.9c0-1-0.2-2-0.4-3
			c-0.1-0.5-0.3-1-0.5-1.8c1.7,0,3.2,0,4.6,0c1.3,0,2.6,0,3.8-0.1c1-0.1,1.6,0.4,2,1.3c1.1,2.5,1.1,4.9-0.5,7.2
			c-1,1.6-2.3,2.9-3.5,4.4c-1.2,1.6-2.5,3.1-3.7,4.7c-1.5,2.2-2.2,4.8-2.2,7.5c0,3.4,2.5,5.2,5.8,4.1c2.7-0.8,4.7-2.6,6.3-4.8
			c3.5-4.7,5.1-10.1,5.6-15.8c0.2-2.5-0.5-4.8-2-6.9c-0.3-0.4-0.4-0.9-0.5-1.3c0.5-0.1,0.9-0.4,1.4-0.4c2.4-0.1,4.8,0,7.2-0.1
			c1.3-0.1,2,0.5,2.3,1.7c0.7,2.6,1,5.3,0.2,7.9c-2,6.4-2.2,12.9-2,19.5c0,2,0,2-2.1,2c-2.5,0-5,0.1-7.6,0.1
			C311.2,33.6,310.8,33.6,310.1,33.6z"
		/>
		<path 
		d="M386.5,4c-0.3,0.5-0.6,0.9-0.9,1.3c-1.5,1.6-3,3.2-4.5,4.8c-1,1.1-1.2,1.1-2.2-0.1c-1.2-1.4-2.4-2.9-3.8-4.2
			c-2.1-2-4.6-2.4-6.8-1c-2.5,1.6-2.5,4.4,0,6c2.5,1.6,5.2,2.5,8.1,3.3c1.8,0.5,3.7,1.1,5.4,1.9c4.8,2.5,6.6,8.1,4.1,12.5
			c-2.4,4.1-6.9,6.4-11.6,5.6c-3.1-0.5-6.2-1.2-9.3-1.9c-1.7-0.4-3.3-0.3-4.8,0.8c-0.7,0.5-1.1,0.2-1.5-0.5c-1.7-3,0.1-6.8,3.5-7.4
			c2.9-0.5,5.4,0.2,7.6,2.1c1.7,1.4,3.3,2.9,5,4.3c1,0.9,2.2,1.4,3.6,1.2c2.6-0.5,3.9-3.7,2.3-6.1c-0.7-1-1.7-2.1-2.8-2.5
			c-2.9-1.1-5.9-1.8-8.9-2.6c-1.5-0.4-3.1-0.7-4.5-1.2c-6-2.2-7.4-10.4-2.7-14.7c3.1-2.8,6.8-3.5,10.8-3.2c2.3,0.2,4.7,0.7,7,0.9
			C381.7,3.8,384,3.9,386.5,4z"
		/>
		<path 
		d="M418.8,20.9c0,3.2,0.5,6.2-0.3,9.3c-0.1,0.4-0.6,0.8-1,1.1c-1.6,1.2-3.3,2.1-5.2,2.2c-3.2,0.1-6.4,0.4-9.6,0.1
			c-5.1-0.5-9.3-4.4-10.6-9.3c-0.9-3.5-1.1-7-0.3-10.6c1.6-6.7,7.1-11.2,14-11.2c1.7,0,3.3,0.2,5,0.6c2.2,0.4,4.3,0.5,6.4-0.3
			c1.2-0.5,1.5-0.3,1.5,1.1c0,2-0.1,3.9-0.1,5.9c0,0.8-0.4,1.1-1.1,1.4c-3.1,1-6.1,1.1-9.1-0.4c-3-1.5-6.1-2-9.4-0.5
			c-5.4,2.4-7.2,8.6-3.6,12.6c2.2,2.3,4.9,3.4,8.1,3.3c4.9-0.2,9.5-1.7,13.6-4.4c0.3-0.2,0.6-0.4,0.9-0.5
			C418.2,21,418.4,20.9,418.8,20.9z"
		/>
		<path 
		d="M223,17.8c0-4.5,0-9,0-13.5c0-1.6,0-1.7,1.7-1.7c1.7,0,3.4,0,5,0c1.6,0,1.8,0.2,1.9,1.8c0.1,2.2,0.1,4.4,0.2,6.6
			c0.1,6.7,0.3,13.5,0.4,20.2c0,0.5,0,1,0,1.4c0,0.7-0.4,1-1.1,1c-2.2,0-4.5,0-6.7,0c-1.3,0-1.4-0.9-1.4-1.8c0-4.3,0-8.6,0-12.8
			C223,18.6,223,18.2,223,17.8z"
		/>
		<path 
		d="M255.6,33.7c-0.3,0.1-0.7,0.4-1.2,0.4c-1.6,0.1-3.3,0.3-4.9,0.3c-2.8,0-4.9-1.3-6.7-3.3c-2.4-2.8-4.9-5.6-7.4-8.3
			c-0.5-0.5-0.9-1-1.3-1.5c-1-1.4-0.9-2.6,0.3-3.8c0.4-0.5,0.9-0.9,1.4-1.3c3.9-3,5.8-7,6-11.9c0.1-1.1,0.4-1.6,1.5-1.6c2,0,4,0,6,0
			c0.9,0,1.4,0.4,1.2,1.3c-0.4,2.1-0.7,4.2-1.2,6.2c-0.9,3.6-3.5,5.5-7.1,6.1c-1.8,0.3-3.5,0.6-5.3,1c-0.3,0.1-0.7,0.1-1,0.3
			c-0.2,0.2-0.4,0.6-0.4,0.9c0,0.2,0.4,0.4,0.7,0.5c0.9,0.2,1.8,0.2,2.7,0.4c4.2,0.9,7.2,3.7,9.8,6.9c0.9,1,1.7,2.2,2.6,3.1
			C252.6,30.9,254,32.2,255.6,33.7z"
		/>
		<path 
		d="M2.8,5.1C3,4.3,3.2,3.8,4.2,3.8c6.5,0.1,13,0.1,19.5,0.1c0.6,0,1.2,0.1,1.8,0.2c2.4,0.5,4.1,2.3,4.2,4.5
			c0.2,2.7-1.2,5.1-3.6,5.7c-2.9,0.8-5.9,1.3-8.9,2c-0.6,0.1-1.2,0.3-1.8,0.6c-0.2,0.1-0.3,0.6-0.4,0.9c0.3,0.1,0.6,0.3,0.8,0.3
			c1.4-0.3,2.8-0.7,4.2-0.9c3-0.5,6-0.7,8.8,0.6c5,2.2,6.1,8.5,2.1,12.2c-2.5,2.3-5.4,3.6-8.7,3.7c-2.8,0.1-5.5,0.3-8.3,0.3
			c-3.1,0-6.3-0.2-9.4-0.2c-0.8,0-1.5-0.3-1.9-1c0-0.6,0-1.1,0-1.7C2.9,30.4,3,29.7,3,29.1c0-7.2,0-14.4-0.1-21.7
			c0-0.4-0.1-0.8-0.1-1.2C2.8,5.9,2.8,5.5,2.8,5.1z M16.3,32.3c0,0,0,0.1,0,0.1c0.8,0,1.7,0,2.5,0c2.4,0.1,4.4-0.9,6.1-2.6
			c1.1-1.1,1.8-2.3,1.3-3.8c-0.5-1.7-1.7-2.8-3.4-3.2c-1.1-0.2-2.2-0.2-3.3-0.3c-1.8-0.1-3.5-0.5-5-1.5c-2.1-1.4-2.1-3.4-0.1-4.9
			c1.3-0.9,2.6-1.8,3.8-2.7c1.3-0.9,1.8-2.2,1.6-3.8c-0.2-1.6-1.2-2.8-2.7-3.2c-1.3-0.4-2.7-0.5-4.1-0.6c-1.1-0.1-1.7,0.6-1.7,1.7
			c0,7.8,0,15.6,0,23.4c0,0.9,0.4,1.3,1.3,1.3C13.9,32.3,15.1,32.3,16.3,32.3z"
		/>
		<path 
		d="M63.5,34.8c-0.4-0.1-0.9-0.2-1.3-0.2c-2,0-3.3-0.8-4.3-2.6c-0.4-0.8-1-1.6-1.5-2.5c-1.4-2.7-3.3-4.8-6-6.2
			c-0.4-0.2-0.8-0.4-1.1-0.7c-1.7-1.3-1.7-3.1-0.1-4.4c1.1-0.9,2.3-1.8,3.5-2.8c1.6-1.3,2.4-2.9,2-5c-0.4-2.1-1.9-3.2-3.9-3.6
			c-1-0.2-2.1-0.3-3.2-0.3c-0.8,0-1.3,0.5-1.3,1.4c0,0.6-0.1,1.3-0.1,1.9c0,7.4,0,14.9,0,22.3c0,1.7-0.1,1.8-1.8,1.9
			c-1.7,0.1-3.4,0.1-5,0.1c-1.4,0-1.8-0.3-1.8-1.7c0-9,0.1-17.9,0.1-26.9c0-0.9,0.1-1.5,1.3-1.6c5.6,0,11.3-0.1,16.9-0.2
			c1.3,0,2.6,0.2,3.8,0.5c3,0.7,4.8,2.7,4.9,5.4c0.2,2.9-1.5,5.7-4.2,6.6c-1.9,0.6-3.9,0.9-5.8,1.3c-0.9,0.2-1.8,0.3-2.7,0.5
			c-0.5,0.1-0.8,0.5-1.3,0.8c0,0.1,0.1,0.3,0.1,0.4c1.2,0.1,2.4,0.1,3.6,0.3c4.8,0.7,8.5,2.9,10.3,7.6c0.3,0.7,0.5,1.3,0.7,2
			c0.5,1.5,1.3,2.8,2.5,3.7c0.3,0.2,0.5,0.6,0.9,1c-0.9,0.3-1.5,0.5-2.2,0.8C65.6,34.8,64.6,34.8,63.5,34.8z"
		/>
		<path 
		d="M103.3,20.9c0.2-5.8,1.4-10.3,5.2-13.8c4.7-4.2,13.1-5.3,18.7-2.5c1.6,0.8,2.9,1.9,3.7,3.5c3.6,7.2,2.2,17.4-4.3,22.8
			c-2,1.6-4.3,2.7-6.8,3c-2.2,0.3-4.6,0.7-6.8,0.5c-4.6-0.5-7.6-3.4-8.8-7.8C103.6,24.4,103.5,22.2,103.3,20.9z M126.8,7.2
			c-0.2,0-0.3,0-0.5,0c-0.2,0-0.4,0.1-0.6,0.1c-6.4,1.2-11.6,4.4-15.8,9.3c-3.3,3.9-3.7,8.1-2,12.8c0.6,1.6,2,2.6,3.7,2.5
			c3.1-0.3,5.9-1.3,7.9-3.8c1.1-1.3,1.9-2.8,2.7-4.4c2.2-4.4,3.6-9,6.4-13.1c0.1-0.2,0.2-0.4,0.2-0.5C129.4,8.4,128.6,7.2,126.8,7.2
			z"
		/>
		<path 
		d="M69.6,20.5c0.1-5,1.1-9.1,4.2-12.5c3.5-3.8,8-5.1,12.9-4.9c2.2,0.1,4.5,0.7,6.6,1.4c2.2,0.7,3.6,2.5,4.4,4.6
			c2.2,5.9,1.9,11.6-1.1,17.1c-2.5,4.7-6.5,7.2-11.8,7.7c-2.2,0.2-4.4,0.8-6.6,0.2c-4.9-1.4-7.4-4.9-8.1-9.8
			C69.8,23,69.7,21.5,69.6,20.5z M73.4,24.4c-0.1,0-0.2,0-0.3,0.1c0.4,1.7,0.7,3.4,1.3,5.1c0.6,1.6,2,2.5,3.6,2.4
			c2.4-0.2,4.6-0.9,6.5-2.5c2-1.6,3.2-3.8,4.2-6c2-4.3,3.5-8.8,6.2-12.7c0.2-0.3,0.3-0.7,0.4-1c0.3-1.3-0.4-2.3-1.7-2.5
			c-0.5-0.1-1,0-1.4,0.1c-6.8,1.3-12.4,4.8-16.6,10.3C74,19.6,73.2,21.8,73.4,24.4z"
		/>
		<path 
		d="M191.1,15.6c-0.3,2.4-0.5,4.5-0.8,7c-0.6-0.4-1-0.6-1.3-0.9c-1.7-1.3-3.6-1.7-5.7-1.5c-0.8,0.1-1.2,0.4-1,1.3
			c0.4,3,0.8,6,1.1,9c0.1,0.8,0.4,1.1,1.1,0.9c4.4-0.9,9.1-1.1,12.8-4.3c0.1-0.1,0.3-0.2,0.5-0.3c0.2-0.1,0.5-0.1,0.9-0.2
			c0.1,0.6,0.2,1.2,0.2,1.7c0,1.6,0,3.2,0,4.8c0,0.9-0.4,1.2-1.3,0.9c-4.7-1.9-9.5-1.7-14.4-1.1c-3,0.4-6,0.7-9.1,1
			c-1,0.1-1.4-0.3-0.9-1c2.2-3.8,1.8-7.8,1.2-11.9c-0.5-3.2-0.9-6.4-1.4-9.6c-0.3-2.1,0.3-3.9,1.3-5.7c0.6-1.1,1.6-1.7,2.9-1.9
			c3.9-0.6,7.7,0,11.5,0.8c3,0.6,5.8,0.5,8.6-1.2c0.5,2.1,0.9,4,1.3,5.9c0.4,2.2,0,2.9-2.3,3.2c-3.3,0.5-6.5-0.2-9.3-1.9
			c-2.4-1.5-4.8-3.1-7.1-4.6c-1-0.6-2-1.4-3.2-0.6c-1.2,0.8-1.4,2.1-1,3.4c0.3,1,0.7,2.1,1.2,3c1.3,2.2,2.7,4.2,4,6.4
			c0.4,0.7,0.9,0.8,1.6,0.5c2.1-0.8,4.2-1.6,6.3-2.4C189.5,16,190.2,15.9,191.1,15.6z"
		/>
		<path 
		d="M137,18.5c0-4.4,0-8.7,0-13.1c0-1.5,0.1-1.6,1.6-1.6c1.8,0,3.5,0,5.3,0c1.1,0,1.5,0.4,1.5,1.5c0.1,7.4,0.3,14.8,0.5,22.2
			c0,1.6,0,3.3,0.1,4.9c0,0.9-0.3,1.3-1.3,1.3c-2-0.1-4-0.1-6,0c-1.3,0-1.6-0.7-1.6-1.8C137,27.5,137,23,137,18.5z"
		/>
		<path 
		d="M168.7,33.8c-0.4,0.2-0.7,0.4-1.1,0.5c-2.5,0.4-4.9,0.6-7.4-0.2c-1.7-0.5-3-1.5-4.2-2.9c-2.3-2.7-4.7-5.3-7.1-8
			c-0.2-0.2-0.3-0.3-0.5-0.5c-2-2.2-2-3.5,0.4-5.3c3.4-2.6,5.6-5.8,6.2-10c0.1-0.4,0.1-0.9,0.1-1.3c0.1-2,0.1-2.1,2.1-2.1
			c1.7,0,3.4,0.1,5.1,0.1c0.9,0,1.2,0.4,1.2,1.2c-0.2,2.7-0.7,5.3-1.9,7.8c-1,2.1-2.7,3.3-5,3.8c-2.1,0.4-4.2,0.8-6.2,1.2
			c-0.6,0.1-1.2,0.6-1.8,0.8c0,0.2,0.1,0.3,0.1,0.5c0.8,0.2,1.6,0.4,2.4,0.5c3.4,0.4,6.2,2,8.4,4.6c1.9,2.2,3.8,4.4,5.8,6.6
			C166.4,32,167.5,32.8,168.7,33.8z"
		/>
		</svg>
		<span className={classes.ada}>{metadata.name}</span></Link>
        </h1>
        <p className={classes.description}>{metadata.description}</p>
        <ul className={classes.list}>
          {twitter && (
            <li className={classes.item}>
              <a
                className={classes.link}
                href={`https://twitter.com/${twitter}`}
              >
                Twitter
              </a>
            </li>
          )}
          {github && (
            <li className={classes.item}>
              <a className={classes.link} href={github}>
                GitHub
              </a>
            </li>
          )}
          {linkedin && (
            <li className={classes.item}>
              <a className={classes.link} href={linkedin}>
                LinkedIn
              </a>
            </li>
          )}
          {!noBlog && (
            <li className={classes.item}>
              <Link className={classes.link} to="/blog">
                Blog
              </Link>
            </li>
          )}
        </ul>
      </div>
    </div>
  );
};

export default Header;
